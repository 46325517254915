import React, { useCallback } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { lime } from '@ant-design/colors';
import { useDispatch } from 'react-redux';
import {useTranslation} from "react-i18next";

import { login } from '../../../http';
import { login as loginAction } from './actions';
import { useCompositeState } from '../../../hooks';
import { BAD_CREDENTIALS } from '../../../errorCodes';

const iconStyle = {
    color: lime.primary
};

function LoginForm() {
    const [state, setState] = useCompositeState({
        loading: false,
        badCredentials: false
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleSubmit = useCallback(async values => {
        setState({ loading: true });

        try {
            const result = await login(values.username, values.password);

            setState({ loading: false });
            dispatch(loginAction(result));
        }
        catch (e) {
            setState({
                loading: false,
                badCredentials: e.code === BAD_CREDENTIALS
            });
        }
    }, [setState, dispatch]);

    const defaultRules = [
        {
            required: true,
            message: t('login-rules')
        }
    ];

    const initialValues = {
        username: '109',
        password: 'trap_id_109'
    };

    return <>
        {state.badCredentials
            ? <Alert type="error" showIcon message={t('login-error')} className="mb-1" />
            : null}
        <Form name="login-form" initialValues={initialValues} onFinish={handleSubmit}>
            <Form.Item name="username" rules={defaultRules}>
                <Input size="large" prefix={<UserOutlined style={iconStyle} />} placeholder={t('login-user')} />
            </Form.Item>
            <Form.Item name="password" rules={defaultRules}>
                <Input.Password size="large" prefix={<UnlockOutlined style={iconStyle} />} placeholder={t('login-password')} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" size="large" block loading={state.loading} htmlType="submit">{t('login-button')}</Button>
            </Form.Item>
            <div className="tac">
                <div>{t('login-help')}</div>
                <a href="mailto:info@agrosense.hu">info@agrosense.hu</a>
            </div>
        </Form>
    </>;
}

export { LoginForm };
