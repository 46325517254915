import { LOGIN } from './actionTypes';

const initialState = JSON.parse(localStorage.getItem('user'));

function user(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            if (action.user) {
                localStorage.setItem('user', JSON.stringify(action.user));
            }
            else {
                localStorage.removeItem('user');
            }

            return action.user;

        default:
            return state;
    }
}

export { user };
