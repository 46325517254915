import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, Card, Button, Descriptions, List, Modal } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';

import './HomePage.css';
import { saveRectangles, removeRectangle } from '../../../http';
import {useTranslation} from "react-i18next";
import Text from "antd/lib/typography/Text";

function PictureAnnotator({ picture, previousPictures, annotations, onAnnotate }) {
    const canvas = useRef();
    const drawAnnotations = useCallback((context, color, annotations, drawIndex = true) => {
        context.save();

        context.strokeStyle = color;
        context.font = '18px sans-serif';
        context.fillStyle = color;
        context.textAlign = 'left';
        context.textBaseline = 'bottom';

        for (let i = 0; i < annotations.length; ++i) {
            const annotation = annotations[i];
            const width = window.innerWidth > 1500 ? annotation.x2 - annotation.x1 : (window.innerWidth < 800 ? (annotation.x2/4 - annotation.x1/4) : (annotation.x2/2 - annotation.x1/2));
            const height = window.innerWidth > 1500 ? annotation.y2 - annotation.y1 : (window.innerWidth < 800 ? (annotation.y2/4 - annotation.y1/4) : (annotation.y2/2 - annotation.y1/2));

            context.strokeRect(window.innerWidth > 1500 ? annotation.x1 : (window.innerWidth < 800 ? (annotation.x1/4) : (annotation.x1/2)) , window.innerWidth > 1500 ? annotation.y1 : (window.innerWidth < 800 ? (annotation.y1/4) : (annotation.y1/2)), width, height);

            if (drawIndex) {
                const text = (i + 1) + '.';
                const { width: textWidth } = context.measureText(text);

                context.fillText(text, annotation.x2 - textWidth - 8, annotation.y2 - 4);
            }
        }

        context.restore();
    }, []);
    const [annotation, setAnnotation] = useState(null);
    const handleMousePress = useCallback(event => setAnnotation({ x1: event.nativeEvent.offsetX, y1: event.nativeEvent.offsetY }), []);
    const handleMouseMove = useCallback(event => {
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;

        if (annotation && x > annotation.x1 && y > annotation.y1) {
            setAnnotation({
                ...annotation,
                x2: x,
                y2: y
            });
        }
    }, [annotation]);
    const handleMouseRelease = useCallback(() => {
        if (annotation.x2 - annotation.x1 >= 50 && annotation.y2 - annotation.y1 >= 50) {
            onAnnotate(annotation);
        }

        setAnnotation(null);
    }, [onAnnotate, annotation, setAnnotation]);
    const [image, setImage] = useState(null);

    const width = window.innerWidth > 1500 ? 1024 : (window.innerWidth < 800 ? 1024/4 : 1024/2);
    const height = window.innerWidth > 1500 ? 576 : (window.innerWidth < 800 ? 576/4 : 576/2);

    useEffect(() => {
        const image = new Image();

        image.src = picture.url;

        image.addEventListener('load', () => setImage(image));
    }, [picture.url]);

    useEffect(() => {
        const context = canvas.current.getContext('2d');

        context.lineWidth = 3;

        if (image) {
            context.clearRect(0, 0, width, height);
            context.drawImage(image, 0, 0, width, height);

            for (const previousPicture of previousPictures) {
                drawAnnotations(context, 'black', previousPicture.annotations, false);
            }

            drawAnnotations(context, 'firebrick', annotations);

            if (annotation) {
                drawAnnotations(canvas.current.getContext('2d'), 'firebrick', [annotation], false);
            }
        }
    });

    return <canvas width={width} height={height} ref={canvas} onMouseDown={handleMousePress} onMouseMove={handleMouseMove} onMouseUp={handleMouseRelease}/>;
}

function Thumbnail({ picture, selected, onSelect }) {
    return <div className={classNames('thumbnail', { selected })} style={{ backgroundImage: `url(${ picture.url })` }} onClick={onSelect}>
        <div className={classNames('thumbnail-caption', { processed: picture.processed })}>{picture.creationDate.format('LL HH:mm')}</div>
    </div>;
}

function PictureProcessorWrapper(props) {
    if (props.pictures.length === 0) {
        return null;
    }

    return <PictureProcessor {...props} />;
}

function PictureProcessor({ pictures, setPictures }) {
    const [annotations, setAnnotations] = useState([]);
    const [selectedPictureIndex, setSelectedPictureIndex] = useState(0);
    const changeSelectedPictureIndex = useCallback(i => {
        setSelectedPictureIndex(i);
        setAnnotations([]);
    }, []);
    const selectedPicture = pictures[selectedPictureIndex];
    const [loading, setLoading] = useState(false);
    const saveAnnotations = useCallback(async () => {
        setLoading(true);

        const response = await saveRectangles(selectedPicture.id, annotations);

        setLoading(false);
        // update pictures
        setPictures(pictures.map((picture, index) =>
            index === selectedPictureIndex
                ? {
                    ...picture,
                    annotations: [...picture.annotations, ...annotations.map((a, i) => ({ id: response[i].rectangle_id, ...a }))]
                }
                : picture));
        setAnnotations([]);
    }, [selectedPicture, annotations, pictures, selectedPictureIndex, setPictures]);
    const { t } = useTranslation();
    const title = t('picture-processing');
    const savedAnnotations = selectedPicture?.annotations ?? [];
    const allAnnotations = savedAnnotations.concat(annotations);
    const removeAnnotation = useCallback((id, index) => {
        Modal.confirm({
            title: t('picture-processing-remove-pest') + `${ index + 1 }?`,
            okText: t('remove'),
            cancelText: t('rename-trap-cancel'),
            async onOk() {
                if (id === undefined) {
                    setAnnotations(a => a.filter((_, i) => i !== index));
                }
                else {
                    await removeRectangle(id);

                    setPictures(pictures.map((picture, pictureIndex) =>
                        pictureIndex === selectedPictureIndex
                            ? {
                                ...picture,
                                annotations: picture.annotations.filter((_, i) => i !== index)
                            }
                            : picture));
                }
            }
        });
    }, [pictures, selectedPictureIndex, setPictures]);

    return <>
        <Row gutter={16} className="mb-1">
            <Col xs={24} sm={24} md={24} lg={18} className="mb-1">
                <Card title={<span className="card-title">{title}</span>} type="inner" size="small">
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Button icon={<ArrowLeftOutlined />} disabled={selectedPictureIndex === 0} onClick={() => changeSelectedPictureIndex(selectedPictureIndex - 1)}/>
                        </Col>
                        <Col>
                            {selectedPicture
                                ? <PictureAnnotator
                                    picture={selectedPicture}
                                    previousPictures={pictures.slice(selectedPictureIndex + 1)}
                                    annotations={allAnnotations}
                                    onAnnotate={annotation => {
                                        setAnnotations([...annotations, annotation]);
                                    }} />
                                : null}
                        </Col>
                        <Col>
                            <Button icon={<ArrowRightOutlined />} disabled={selectedPictureIndex === pictures.length - 1} onClick={() => changeSelectedPictureIndex(selectedPictureIndex + 1)}/>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6}>
                <Card title={<span className="card-title">{t('data')}</span>} type="inner" size="small" className="mb-1">
                    <Descriptions column={1}>
                        {/* <Descriptions.Item label="Ragacslap lejárati dátuma">{moment(selectedSection.sticky.expirationDate).format('LL')}</Descriptions.Item> */}
                        <Descriptions.Item label={t('picture-processing-new-pest-count')}>{allAnnotations.length}</Descriptions.Item>
                        <Descriptions.Item label={t('picture-processing-all-pest-count')}>{sumBy(pictures, picture => picture.annotations.length) + annotations.length}</Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card title={<span className="card-title">{t('picture-processing-pest-count-on-picture')}</span>} type="inner" size="small" extra={<Button type="primary" onClick={() => saveAnnotations()} loading={loading}>{t('rename-trap-save')}{allAnnotations.length ? '' : t('picture-processing-pest-as-empty')}</Button>}>
                    {allAnnotations.length
                        ? <List
                            dataSource={allAnnotations}
                            renderItem={(a, i) => {
                                const j = i - (a.id ? 0 : savedAnnotations.length);

                                return <List.Item>{t('picture-processing-pest-name')} #{i + 1} <Button type="link" icon={<DeleteOutlined />} shape="circle" onClick={() => removeAnnotation(a.id, j)}/></List.Item>;
                            }}
                            className="mb-1" />
                        : <Text type="secondary">{t('no-pests')}</Text>}
                </Card>
            </Col>
        </Row>
        <Row className="mb-2">
            <Col span={24}>
                <Card>
                    <Row gutter={16} align="middle" className="thumbnails">
                        {pictures.map((picture, index) =>
                            <Col key={index}>
                                <Thumbnail
                                    picture={picture}
                                    selected={index === selectedPictureIndex}
                                    onSelect={() => changeSelectedPictureIndex(index)} />
                            </Col>)}
                    </Row>
                </Card>
            </Col>
        </Row>
    </>;
}

export { PictureProcessorWrapper as PictureProcessor };
