import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useReducer, useEffect } from 'react';
import { message } from 'antd';

import { EXPIRED_TOKEN } from './errorCodes';
import { logout } from './components/pages/LoginPage/actions';

function useUser() {
    return useSelector(store => store.user);
}

function useQueryParam(param) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    return params.get(param);
}

function useCompositeState(initialState) {
    return useReducer((state, patch) => ({
        ...state,
        ...patch
    }), initialState);
}

function useAsyncData(dataSource, params = []) {
    const user = useUser();
    const [state, setState] = useCompositeState({
        loading: true,
        data: null
    });
    const dispatch = useDispatch();

    useEffect(() => {
        let mounted = true;

        if (!state.loading) {
            setState({ loading: true });
        }

        dataSource.call(window, user?.token, ...params)
            .then(data => {
                if (mounted) {
                    setState({ loading: false, data });
                }
            })
            .catch(e => {
                if (mounted && e.code === EXPIRED_TOKEN) {
                    message.error('Session expired, please login again!', 3, () => dispatch(logout()));
                }
            });

        return () => {
            mounted = false;
        };
    }, params); // eslint-disable-line react-hooks/exhaustive-deps

    return [state, data => setState({ data })];
}

export {
    useUser,
    useQueryParam,
    useCompositeState,
    useAsyncData
};
