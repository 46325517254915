import React, { useState } from 'react';
import { Card, DatePicker, Spin } from 'antd';
import moment from 'moment';

import { Chart } from '../../Chart';
import { useAsyncData } from '../../../hooks';
import { getTemperatures } from '../../../http';
import {useTranslation} from "react-i18next";

function getDateRange(startDate, endDate) {
    let currentDate = moment(startDate).startOf('day');
    const range = [];

    while (currentDate.isSameOrBefore(endDate, 'day')) {
        range.push(moment(currentDate));
        currentDate.add(1, 'day');
    }

    return range;
}
//replace 10 with limit
function calculateTemperature(minTemperature, maxTemperature) {
    return (Math.max(minTemperature, 10) + Math.max(maxTemperature, 10)) / 2 - 10;
}
//replace 14 with
function calculateHeatSum(biofixPoint, temperatures, length = 14) {
    const range = getDateRange(biofixPoint, moment(biofixPoint).add(length, 'day'));
    const heatArray = [];

    for (let i = 0; i < range.length; ++i) {
        const formattedDate = range[i].format();

        if (temperatures[formattedDate]) {
            const { temperatureLow, temperatureHigh } = temperatures[formattedDate];

            heatArray.push({
                temperature: (heatArray[heatArray.length - 1]?.temperature ?? 0) + calculateTemperature(temperatureLow, temperatureHigh),
                date: formattedDate
            });
        }
        else {
            heatArray.push(heatArray[heatArray.length - 1]);
        }
    }

    return heatArray;
}

function HeatSumChartCard({ biofixPoints, latitude, longitude }) {
    const [dates, setDates] = useState([moment().subtract(10, 'day'), moment().add(5, 'day')]);
    const [{ loading, data }] = useAsyncData(getTemperatures, [dates, biofixPoints, latitude, longitude]);

    const dateRange = dates ? getDateRange(...dates) : [];
    const dateFormat = 'MMM[.] Do';
    const labels = dateRange.map(d => d.format(dateFormat));
    const today = moment().format(dateFormat);
    const datasets = biofixPoints.map(biofixPoint => {
        if (loading) {
            return [];
        }

        const heatArray = calculateHeatSum(biofixPoint.date, data);

        return dateRange.map(d => {
            const foundItem = heatArray.find(h => h && h.date === d.format());
            return foundItem ? foundItem.temperature : null;
        });
    });
    const { t } = useTranslation();
//replace 85
    return <Card title={<span className="card-title">{t('heatsum')}</span>} type="inner" size="small">
        <DatePicker.RangePicker
            value={dates}
            onChange={setDates}
            disabledDate={date => date.isAfter(moment().add(7, 'days'))}
            format="LL"
            className="mb-1" />
        <div>
            <Spin spinning={loading}>
                <Chart
                    labels={labels}
                    datasets={datasets}
                    annotations={[
                        {
                            scaleID: 'x-axis-0',
                            type: 'line',
                            mode: 'vertical',
                            value: today,
                            borderColor: 'green'
                        },
                        {
                            yScaleID: 'y-axis-0',
                            type: 'box',
                            yMin: 85,
                            backgroundColor: 'rgba(255, 0, 0, 0.5)'
                        },
                        {
                            xScaleID: 'x-axis-0',
                            yScaleID: 'y-axis-0',
                            type: 'box',
                            xMin: today,
                            yMin: 0,
                            yMax: 85,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)'
                        },
                    ]}
                    yTicksConfig={{
                        min: 0,
                        max: 128,
                        stepSize: 8
                    }} />
            </Spin>
        </div>
    </Card>;
}

export { HeatSumChartCard };
