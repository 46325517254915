import React, {useRef, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Layout, Row, Col, Card, Button, Modal, Input} from 'antd';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {EditOutlined} from "@ant-design/icons";
import _ from "lodash";

import {useUser, useAsyncData} from '../../../hooks';
import {SectionsCard} from './SectionsCard';
import {getSections, getPictures, renameCamera} from '../../../http';
import {Header} from './Header';
import {PictureProcessor} from './PictureProcessor';
import {SwarmingCurveCard} from './SwarmingCurveCard';
import {HeatSumChartCard} from './HeatSumChartCard';
import {login} from "../LoginPage/actions";
import BatteryCard from "./BatteryCard";

function HomePage() {
    const user = useUser();
    const dispatch = useDispatch();
    const cameraNameInputRef = useRef(null);
    const [sections, setSections] = useAsyncData(getSections, [user?.camera?.id]);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
    const selectedSection = sections.data ? sections.data[selectedSectionIndex] : null;
    const nextSection = selectedSectionIndex > 0 && sections.data ? sections.data[selectedSectionIndex - 1] : null;
    const [pictures, setPictures] = useAsyncData(getPictures, [user?.camera?.driveFolderId]);
    const { t } = useTranslation();

    if (!user) {
        return <Redirect to="/login?referrer=%2F"/>;
    }

    const filteredPictures = selectedSection && !pictures.loading
        ? _.chain(pictures.data)
            .filter(picture => picture.creationDate.isSameOrAfter(selectedSection.startDate, 'day')
                && (nextSection ? picture.creationDate.isBefore(nextSection.startDate, 'day') : true))
            .sortBy('creationDate')
            .reverse()
            .value()
        : []

    return <Layout>
        <Header/>
        <Layout.Content className="p-2">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Card title={<span className="card-title">{t('trap-name')}</span>} type="inner" className="info-card" size="small">
                        {` ${user.camera.name} (#${user.camera.id}) `}
                        <Button type="link" icon={<EditOutlined/>} onClick={() => {
                            Modal.confirm({
                                title: <span className="card-title">{t('rename-trap')}</span>,
                                content: <Input defaultValue={user.camera.name} ref={cameraNameInputRef}/>,
                                cancelText: t('rename-trap-cancel'),
                                okText: t('rename-trap-save'),
                                onOk: () => {
                                    const newName = cameraNameInputRef.current.state.value;

                                    return renameCamera(user.token, user.camera.id, newName)
                                        .then(() => dispatch(login({
                                            ...user,
                                            camera: {
                                                ...user.camera,
                                                name: newName
                                            }
                                        })));
                                }
                            });
                        }}/>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <BatteryCard
                        voltage={user.camera.batteryVoltage}
                    />
                </Col>
            </Row>
            <SectionsCard
                sections={sections}
                selectedSectionIndex={selectedSectionIndex}
                onSectionChange={setSelectedSectionIndex}
                addSection={section => setSections([section, ...sections.data])}
                addSticky={sticky => setSections(sections.data.map((s, i) => i === selectedSectionIndex ? {
                    ...s,
                    stickies: [sticky, ...s.stickies]
                } : s))}
                addPheromone={pheromone => setSections(sections.data.map((s, i) => i === selectedSectionIndex ? {
                    ...s,
                    pheromones: [pheromone, ...s.pheromones]
                } : s))}
            />
            <Row gutter={[0,32]}>
                <Col span={24}>
                    <SwarmingCurveCard
                        pictures={filteredPictures}
                        biofixPoints={selectedSection?.biofixPoints ?? []}
                        sectionId={selectedSection?.id}
                        onBiofixPointAdd={(p, id) =>
                            setSections(sections.data.map((s, i) => {
                                const x = i === selectedSectionIndex
                                    ? {...s, biofixPoints: [{date: p, id}, ...s.biofixPoints]}
                                    : s;

                                return x;
                            }))}
                        onBiofixPointRemove={id => setSections(sections.data.map((s, i) => i === selectedSectionIndex ? {
                            ...s,
                            biofixPoints: s.biofixPoints.filter(p => p.id !== id)
                        } : s))}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <PictureProcessor pictures={filteredPictures} setPictures={setPictures} />
                </Col>
            </Row>
            <Row gutter={[0,0]}>
                <Col span={24}>
                    {selectedSection
                        ? <HeatSumChartCard
                            biofixPoints={selectedSection?.biofixPoints ?? []}
                            latitude={selectedSection?.latitude}
                            longitude={selectedSection?.longitude}/>
                        : null}
                </Col>
            </Row>
        </Layout.Content>
        <Layout.Footer className="footer">
            {t('footer')}
        </Layout.Footer>
    </Layout>;
}

export {HomePage};
