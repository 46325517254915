import React, {useState} from 'react';
import {Card, Row, Col, Select, Button, Spin, Modal, Form, DatePicker, Divider, Input} from 'antd';
import {PlusOutlined, SaveOutlined} from '@ant-design/icons';
import GoogleMap from 'google-map-react';
import {useTranslation} from "react-i18next";
import moment from "moment";

import {useUser} from '../../../hooks';
import {addNewSection, addNewSticky, addNewPheromone} from '../../../http';

function mapSectionToOption(section, index) {
    return <Select.Option key={index} value={index}>{section.name}</Select.Option>;
}

function UtilityInfo({utilities}) {
    const {t} = useTranslation();
    if (utilities.length === 0 || !utilities[0]) {
        return t('no-data');
    }

    return moment(utilities[0]).locale('hu').format('LL');
}

const defaultRules = [
    {required: true, message: 'The field is required!'}
];

function Marker() {
    return <div style={{width: 16, height: 16, backgroundColor: 'red', borderRadius: '50%'}}></div>
}

function Map({value, onChange}) {
    const [center] = useState({lat: value.latitude, lng: value.longitude});

    return <div style={{width: '100%', height: 256}}>
        <GoogleMap
            bootstrapURLKeys={{key: 'AIzaSyDLSMueqaDkKPfqGlLdFhNfOLDsDIIpUg8'}}
            defaultCenter={center}
            defaultZoom={8}
            onClick={event => onChange({latitude: event.lat, longitude: event.lng})}>

            <Marker lat={value.latitude} lng={value.longitude}/>
        </GoogleMap>
    </div>;
}

function NewSectionDialog({visible, onSave, onCancel}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const initialValues = {
        pest: t('measurement-phase-modal-pest-name'),
        coordinates: {
            latitude: 47.4813602,
            longitude: 18.9902199
        }
    };

    return <Modal
        visible={visible}
        title={<span className="card-title">{t('measurement-phase-modal-title')}</span>}
        okText={t('rename-trap-save')}
        cancelText={t('rename-trap-cancel')}
        onOk={() => {
            setLoading(true);

            form.validateFields()
                .then(values => {
                    setLoading(false);
                    form.resetFields();
                    onSave(values);
                })
                .catch(() => setLoading(false));
        }}
        okButtonProps={{loading}}
        onCancel={onCancel}>

        <Form form={form} initialValues={initialValues} name="new-section-form" labelCol={{span: 6}}>
            <Form.Item name="pest" label={t('measurement-phase-modal-pest')}>
                <Select disabled>
                    <Select.Option value="Apple moth">{t('measurement-phase-modal-pest-name')}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="startDate" label={t('measurement-phase-modal-date')} rules={defaultRules}>
                <DatePicker format="LL" className="width-input"/>
            </Form.Item>
            {/* <Form.Item name="latitude" label="Szélesség" rules={defaultRules}>
                <Input />
            </Form.Item>
            <Form.Item name="longitude" label="Hosszúság" rules={defaultRules}>
                <Input />
            </Form.Item> */}
            <Form.Item name="coordinates">
                <Map/>
            </Form.Item>
        </Form>
    </Modal>;
}

function NewUtilityDialog({visible, onStickySave, onPheromoneSave, onCancel}) {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    const {t} = useTranslation();

    return <Modal
        visible={visible}
        footer={null}
        onCancel={onCancel}>

        <h2 className="card-title">{t('utility-modal-sticky')}</h2>
        <Form form={form1} name="new-sticky-form" labelCol={{span: 10}}>
            <Form.Item name="insertDate" label={t('utility-modal-sticky-insertion')} rules={defaultRules}>
                <DatePicker format="LL" placeholder={t('select-date')} className="width-input"/>
            </Form.Item>
            <Form.Item name="expirationDate" label={t('sticky-expiration')} rules={defaultRules}>
                <DatePicker format="LL" placeholder={t('select-date')} className="width-input"/>
            </Form.Item>
            <Row justify={"end"}>
                <Button type="primary" icon={<SaveOutlined/>} shape={"round"} onClick={() => {
                    form1.validateFields().then(values => {
                        form1.resetFields();
                        onStickySave(values);
                    });
                }}>{t('rename-trap-save')}</Button>
            </Row>
        </Form>
        <Divider/>
        <h2 className="card-title">{t('utility-modal-pheromone')}</h2>
        <Form form={form2} name="new-pheromone-form" labelCol={{span: 10}}>
            <Form.Item name="name" label={t('utility-modal-pheromone-name')} rules={defaultRules}>
                <Input/>
            </Form.Item>
            <Form.Item name="insertDate" label={t('utility-modal-pheromone-insertion')} rules={defaultRules}>
                <DatePicker format="LL" placeholder={t('select-date')} className="width-input"/>
            </Form.Item>
            <Form.Item name="expirationDate" label={t('pheromone-expiration')} rules={defaultRules}>
                <DatePicker format="LL" placeholder={t('select-date')} className="width-input"/>
            </Form.Item>
            <Row justify={"end"}>
                <Button type="primary" icon={<SaveOutlined/>} shape={"round"} onClick={() => {
                    form2.validateFields().then(values => {
                        form2.resetFields();
                        onPheromoneSave(values);
                    });
                }}>{t('rename-trap-save')}</Button>
            </Row>
        </Form>
    </Modal>;
}

function SectionsCard({sections, selectedSectionIndex, onSectionChange, addSection, addSticky, addPheromone}) {
    const selectedSection = sections.loading ? null : sections.data[selectedSectionIndex];
    const [newSectionDialogVisible, setNewSectionDialogVisible] = useState(false);
    const [newUtilityDialogVisible, setNewUtilityDialogVisible] = useState(false);
    const user = useUser();
    const {t} = useTranslation();

    if (sections.loading) {
        return <Spin spinning>
            <Card/>
        </Spin>;
    }

    return <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12}>
            <Card title={<span className="card-title">{t('measurement-phase')}</span>} type="inner" size="small"
                  className="section-card"
                  extra={<Button type="primary" shape="circle" icon={<PlusOutlined/>} onClick={() => {
                      setNewSectionDialogVisible(true);
                  }}/>}>
                <Row justify="center">
                    <Select
                        value={selectedSectionIndex}
                        onChange={onSectionChange}
                        dropdownMatchSelectWidth={false}
                        size="large"
                    >
                        {sections.data.map(mapSectionToOption)}
                    </Select>
                </Row>
            </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
            <Card title={<span className="card-title">{t('utility')}</span>} type="inner" size="small"
                  className="section-card"
                  extra={<Button type="primary" shape="circle" icon={<PlusOutlined/>} onClick={() => {
                      setNewUtilityDialogVisible(true);
                  }}/>}>
                <Row justify="center">
                    <Col span={12}>
                        {t('sticky-expiration')}<br/>
                        <UtilityInfo utilities={selectedSection.stickies}/>
                    </Col>
                    <Col span={12}>
                        {t('pheromone-expiration')}<br/>
                        <UtilityInfo utilities={selectedSection.pheromones}/>
                    </Col>
                </Row>
            </Card>
        </Col>
        <NewSectionDialog
            visible={newSectionDialogVisible}
            onSave={values => {
                addNewSection(user.token, user.camera.id, values).then(newSection => {
                    setNewSectionDialogVisible(false);
                    addSection(newSection);
                });
            }}
            onCancel={() => setNewSectionDialogVisible(false)}/>
        <NewUtilityDialog
            visible={newUtilityDialogVisible}
            onStickySave={values => {
                addNewSticky(user.token, selectedSection.id, values).then(newSticky => {
                    setNewUtilityDialogVisible(false);
                    addSticky(newSticky);
                });
            }}
            onPheromoneSave={values => {
                addNewPheromone(user.token, selectedSection.id, values).then(newPheromone => {
                    setNewUtilityDialogVisible(false);
                    addPheromone(newPheromone);
                });
            }}
            onCancel={() => setNewUtilityDialogVisible(false)}/>
    </Row>;
}

export {SectionsCard};
