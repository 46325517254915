import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-annotation/chartjs-plugin-annotation';
import moment from 'moment';

function Component({ labels, datasets, annotations, yTicksConfig, onClick }) {
    const canvas = useRef(null);
    const chart = useRef(null);

    useEffect(() => {
        if (!chart.current) {
            chart.current = new Chart(canvas.current, {
                type: 'line',
                data: {
                    labels,
                    datasets: datasets.map(data =>
                        ({
                            data,
                            fill: false,
                            borderColor: 'black',
                            pointBorderColor: 'black',
                            pointBackgroundColor: 'black',
                            pointRadius: 4
                        }))
                },
                options: {
                    legend: { display: false },
                    scales: {
                        yAxes: [
                            {
                                ticks: yTicksConfig ?? {
                                    stepSize: 1
                                }
                            }
                        ],
                        xAxes: [
                            {
                                gridLines: { display: false },
                            }
                        ]
                    },
                    maintainAspectRatio: false,
                    annotation: annotations
                        ? {
                            drawTime: 'beforeDatasetsDraw',
                            annotations
                        }
                        : undefined,
                    onClick: (_, elements) => {
                        if (elements.length) {
                            onClick(moment(chart.current.data.labels[elements[0]._index], 'LL'));
                        }
                    }
                }
            });
        }
    });

    useEffect(() => {
        chart.current.data.labels = labels;
        chart.current.config.options.annotation.annotations = annotations;

        chart.current.data.datasets.forEach((dataset, i) => {
            dataset.data = datasets[i];
        });
        chart.current.update();
    });

    return <canvas ref={canvas} height="350"></canvas>;
}

export { Component as Chart };
