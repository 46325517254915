import { message } from 'antd';
import moment from 'moment';

import { BAD_CREDENTIALS, EXPIRED_TOKEN, UNKNOWN_ERROR } from './errorCodes';

async function delay(data) {
    return new Promise(resolve => {
        setTimeout(() => resolve(data), 1000);
    });
}
const baseUrl = 'https://mothtrap-pro.herokuapp.com/api/v1';
//const baseUrl = '';

async function query({ method, path, token, data }) {
    const headers = {
        'Content-Type': 'application/json'
    };

    if (token) {
        headers['X-Token'] = token;
    }

    const response = await fetch(baseUrl + path, {
        method,
        headers,
        body: JSON.stringify(data)
    });

    /* eslint-disable no-throw-literal */
    switch (response.status) {
        case 200:
        case 201:
            return response.json();

        case 401:
            throw { code: token ? EXPIRED_TOKEN : BAD_CREDENTIALS };

        default:
            message.error('Váratlan hiba történt, kérjük próbálja újra késöbb!');

            throw { code: UNKNOWN_ERROR };
    }
    /* eslint-enable no-throw-literal */
}

async function getCameras(token) {
    return query({
        path: '/cameras/',
        token
    });
    // return delay([
    //     {
    //         camera_id: 1,
    //         camera_name: 'Teszt név',
    //         folder_id: '1ANX5RJTYdA1BiGHXVjEVvD1gia5rs9AM'
    //     }
    // ]);
}

async function getVoltage(_, cameraId) {
    return query({ path: '/current-voltage/?camera_id=' + cameraId });
    // return delay({
    //     current_voltage: 7.9
    // });
}

async function login(username, password) {
    const { jwt_token: token } = await query({
        method: 'POST',
        path: '/users/login',
        data: {
            email: username,
            password
        }
    });
    // const token = 'token';
    const [camera] = await getCameras(token);
    const voltage = await getVoltage(token, camera.camera_id);

    return delay({
        token,
        camera: {
            id: camera.camera_id,
            name: camera.camera_name,
            batteryVoltage: voltage.current_voltage,
            driveFolderId: camera.folder_id
        }
    });
}

async function renameCamera(_, cameraId, name) {
    return query({
        method: 'PUT',
        path: '/cameras/',
        data: {
            camera_id: cameraId,
            camera_name: name
        }
    });
    // return delay({ name });
}

async function _getSections(_, cameraId) {
    if (!cameraId) {
        return [];
    }

    const sections = await query({ path: '/measurement_phase/?camera_id=' + cameraId });

    sections.sort((a, b) => moment(b.start_date).valueOf() - moment(a.start_date).valueOf());

    return sections;
    // return delay([
    //     {
    //         measurement_phase_id: 1,
    //         measurement_phase_name: 'Almamoly - 2020. június 26.',
    //         latitude: 46.6178671,
    //         longitude: 19.2209547,
    //         start_date: '2020-06-26T09:06:00.000000+00:00'
    //     },
    //     {
    //         measurement_phase_id: 2,
    //         measurement_phase_name: 'Almamoly - 2020. július 1.',
    //         latitude: 46.6178671,
    //         longitude: 19.2209547,
    //         start_date: '2020-07-01T00:01:00.000000+00:00'
    //     }
    // ]);
}

async function getStickies(_, sectionId) {
    return query({
        path: '/sticky/?measurement_phase_id=' + sectionId
    });
    // if (sectionId === 1) {
    //     return delay([
    //         {
    //             created_date: '2020-06-29T00:00:00.000000+00:00',
    //             expiration_date: '2020-07-02T00:00:00.000000+00:00'
    //         },
    //         {
    //             created_date: '2020-07-02T00:00:00.000000+00:00',
    //             expiration_date: '2020-07-05T00:00:00.000000+00:00'
    //         }
    //     ]);
    // }
    // else {
    //     return delay([]);
    // }
}

async function getPheromones(_, sectionId) {
    return await query({
        path: '/pheromon/?measurement_phase_id=' + sectionId
    });
    // if (sectionId === 1) {
    //     return delay([]);
    // }
    // else {
    //     return delay([
    //         {
    //             created_date: '2020-07-01T00:00:00.000000+00:00',
    //             expiration_date: '2020-07-08T00:00:00.000000+00:00'
    //         }
    //     ]);
    // }
}

async function getBiofixPoints(_, sectionId) {
    return query({
        path: '/biofix_point/?measurement_phase_id=' + sectionId
    });
}

async function getSections(_, cameraId) {
    const sections = await _getSections(null, cameraId);

    return Promise.all(sections.map(async section => {
        const stickies = await getStickies(null, section.measurement_phase_id);
        const pheromones = await getPheromones(null, section.measurement_phase_id);
        const biofixPoints = await getBiofixPoints(null, section.measurement_phase_id);

        return {
            id: section.measurement_phase_id,
            name: section.measurement_phase_name,
            latitude: section.latitude,
            longitude: section.longitude,
            startDate: moment(section.start_date),
            stickies: stickies.map(sticky => sticky.expiration_date ? moment(sticky.expiration_date) : null).reverse(),
            pheromones: pheromones.map(pheromone => pheromone.expiration_date ? moment(pheromone.expiration_date) : null).reverse(),
            biofixPoints: biofixPoints.map(biofixPoint => ({
                id: biofixPoint.biofix_point_id,
                date: biofixPoint.created_date ? moment(biofixPoint.created_date) : null
            }))
        };
    }));
}

async function addNewSection(_, cameraId, section) {
    const { measurement_phase_name, latitude, longitude } = await query({
        method: 'POST',
        path: '/measurement_phase/',
        data: {
            camera_id: cameraId,
            measurement_phase_name: section.pest + ' - ' + section.startDate.format('LL'),
            latitude: section.coordinates.latitude,
            longitude: section.coordinates.longitude,
            start_date: section.startDate.format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ')
        }
    });

    return {
        name: measurement_phase_name,
        startDate: section.startDate,
        latitude,
        longitude,
        stickies: [],
        pheromones: []
    };
    // return delay({
    //     name: section.pest + ' - ' + section.startDate.format('LL'),
    //     startDate: section.startDate,
    //     stickies: [],
    //     pheromones: []
    // });
}

async function addNewSticky(_, sectionId, sticky) {
    await query({
        method: 'POST',
        path: '/sticky/',
        data: {
            measurement_phase_id: sectionId,
            created_date: sticky.insertDate.format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ'),
            expiration_date: sticky.expirationDate.format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ')
        }
    });

    return sticky.expirationDate;
    // return delay(sticky.expirationDate);
}

async function addNewPheromone(_, sectionId, pheromone) {
    await query({
        method: 'POST',
        path: '/pheromon/',
        data: {
            measurement_phase_id: sectionId,
            created_date: pheromone.insertDate.format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ'),
            expiration_date: pheromone.expirationDate.format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ'),
            pheromon_name: pheromone.name
        }
    });

    return pheromone.expirationDate;
    // return delay(pheromone.expirationDate);
}

async function getPictures(token, driveFolderId) {
    if (!driveFolderId) {
        return [];
    }

    const response = await query({
        path: '/pictures/?drive_folder_id=' + driveFolderId
    });

    return response.reverse().map(picture => ({
        id: picture.drive_picture_id,
        url: baseUrl + '/pictures/getPicture?picture_id=' + picture.drive_picture_id,
        creationDate: moment(picture.created_date),
        annotations: picture.rectangles
            .filter(r => r.height) // to-do: remove if database has been cleaned
            .map(r => ({
                id: r.rectangle_id,
                x1: r.startx,
                y1: r.starty,
                x2: r.startx + r.width,
                y2: r.starty + r.height
            })),
        processed: picture.is_processed
    }));
}

async function saveRectangles(drivePictureId, annotations) {
    return query({
        method: 'POST',
        path: '/pictures/addRectangles',
        data: annotations.map(({ x1, y1, x2, y2 }) => ({
            drive_picture_id: drivePictureId,
            startX: x1,
            startY: y1,
            width: x2 - x1,
            height: y2 - y1
        }))
    });
}

async function removeRectangle(id) {
    return query({
        method: 'DELETE',
        path: '/pictures/deleteRectangle?rectangle_id=' + id
    });
}

async function addBiofixPoint(point, sectionId) {
    return query({
        method: 'POST',
        path: '/biofix_point/',
        data: {
            created_date: point.format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ'),
            measurement_phase_id: sectionId
        }
    });
}

function getDateRange(startDate, endDate) {
    let currentDate = moment(startDate).startOf('day');
    const range = [];

    while (currentDate.isSameOrBefore(endDate, 'day')) {
        range.push(moment(currentDate));
        currentDate.add(1, 'day');
    }

    return range;
}

// function fakeForecast(n) {
//     const result = [];

//     for (let i = 0; i < n; ++i) {
//         result.push({
//             temperatureLow: 7 + random(0, 5),
//             temperatureHigh: 18 + random(0, 5),
//             icon: 'clear-day'
//         });
//     }

//     return result;
// }

async function callDarkSky(coordinates, timestamp) {
    // const response = await fetch(`/darksky/${ coordinates.join(',') }` + (timestamp !== undefined ? ',' + timestamp : '') + '?units=si');
    // const response = await fetch(`https://cors-anywhere.herokuapp.com/https://api.darksky.net/forecast/5db592d19847cc806d6d089eefbd5d0e/${ coordinates.join(',') }` + (timestamp !== undefined ? ',' + timestamp : '') + '?units=si');
    return query({
        path: `/forecast/?latitude=${ coordinates[0] }&longitude=${ coordinates[1] }` + (timestamp !== undefined ? '&timestamp=' + timestamp : '')
    });
}

async function getTemperatures(_, dates, biofixPoints, latitude, longitude) {
    if (!dates) {
        return {};
    }

    const coordinates = [latitude, longitude];

    const startDate = moment.min(...biofixPoints.map(x => x.date), dates[0]);
    const endDate = moment.max(...biofixPoints.map(x => x.date), dates[1]);
    const range = getDateRange(startDate, endDate);
    const today = moment();
    const temperatures = {};

    for (let i = 0; i < range.length; ++i) {
        const date = range[i];
        const isPast = date.isBefore(today, 'day');

        const response = await callDarkSky(coordinates, isPast ? date.unix() : undefined);

        for (let j = 0; j < response.daily.data.length; ++j) {
            const k = i + j;

            if (k < range.length) {
                temperatures[range[k].format()] = response.daily.data[j];
            }
        }

        if (!isPast) {
            break;
        }
    }

    return temperatures;
}

async function removeBiofixPoint(id) {
    return query({
        method: 'DELETE',
        path: '/biofix_point/?biofix_point_id=' + id
    });
}

export {
    login,
    renameCamera,
    getSections,
    addNewSection,
    addNewSticky,
    addNewPheromone,
    getPictures,
    saveRectangles,
    addBiofixPoint,
    getBiofixPoints,
    getTemperatures,
    removeRectangle,
    removeBiofixPoint
};
