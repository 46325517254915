import React, { useEffect, useRef } from 'react';
import { Card, Modal } from 'antd';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

import { Chart } from '../../Chart';
import { addBiofixPoint, removeBiofixPoint } from '../../../http';
import {useTranslation} from "react-i18next";

function SwarmingCurveCardWrapper(props) {
    if (!props.pictures.length) {
        return null;
    }

    return <SwarmingCurveCard {...props} />
}

function SwarmingCurveCard({ pictures, biofixPoints, sectionId, onBiofixPointAdd, onBiofixPointRemove }) {
    const pictureGroups = groupBy(pictures.slice().reverse(), picture => picture.creationDate.format('LL'));
    const pointsRef = useRef();
    const { t } = useTranslation();
    useEffect(() => {
        pointsRef.current = biofixPoints;
    }, [biofixPoints])

    return <Card title={<span className="card-title">{t('swarming-curve')}</span>} type="inner" size="small">
        <Chart
            labels={Object.keys(pictureGroups)}
            datasets={[Object.values(pictureGroups).map(group => sumBy(group, picture => picture.annotations.length))]}
            annotations={biofixPoints.map(p => ({
                scaleID: 'x-axis-0',
                type: 'line',
                mode: 'vertical',
                value: p.date.format('LL'),
                borderColor: 'red',
                borderWidth: 2
            }))}
            onClick={label => {
                const p = pointsRef.current.find(x => x.date.isSame(label, 'day'));

                if (!p) {
                    Modal.confirm({
                        title: t('swarming-curve-modal-biofix'),
                        content: t('date') + `: ${ label.format('LL') }`,
                        okText: t('insert'),
                        cancelText: t('rename-trap-cancel'),
                        onOk: () => addBiofixPoint(label, sectionId).then(r => onBiofixPointAdd(label, r.biofix_point_id))
                    });
                }
                else {
                    Modal.confirm({
                        title: t('swarming-curve-modal-biofix-remove'),
                        content: t('date') + `: ${ label.format('LL') }`,
                        okText: t('remove'),
                        okType: 'danger',
                        cancelText: t('rename-trap-cancel'),
                        onOk: () => removeBiofixPoint(p.id).then(() => onBiofixPointRemove(p.id))
                    });
                }
            }} />
    </Card>;
}

export { SwarmingCurveCardWrapper as SwarmingCurveCard };
