import React from 'react';
import {Card, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryThreeQuarters,
    faExclamation
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

function getBatteryLevelIcon(batteryVoltage) {
    if (batteryVoltage < 6.5) {
        return faExclamation;
    } else if (batteryVoltage < 7) {
        return faBatteryQuarter;
    } else if (batteryVoltage < 7.5) {
        return faBatteryHalf;
    } else if (batteryVoltage < 8) {
        return faBatteryThreeQuarters;
    } else {
        return faBatteryFull;
    }
}

const BatteryCard = ({voltage}) => {

    const { t } = useTranslation();

    return (
        <Card title={<span className="card-title">{t('trap-battery-level')}</span>} type="inner" size="small" className="info-card" extra={<Tooltip title={voltage + ' V'}>
            <FontAwesomeIcon icon={getBatteryLevelIcon(voltage)} size="lg" />
        </Tooltip>}>
            {voltage <= 6.5 && <p>{t('battery-state1')}</p>}
            {6.5 < voltage && voltage <= 7 && <p>{t('battery-state2')}</p>}
            {7 < voltage && voltage <= 7.5 && <p>{t('battery-state3')}</p>}
            {7.5 < voltage && voltage <= 8 && <p>{t('battery-state4')}</p>}
            {8 < voltage && <p>{t('battery-state5')}</p>}
        </Card>
    );
};

export default BatteryCard;