import React from 'react';
import {Layout, Row, Col, Card} from 'antd';
import {Redirect} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import {LoginForm} from './LoginForm';
import {useUser, useQueryParam} from '../../../hooks';
import {Logo} from '../../Logo';
import {Header} from "../HomePage/Header";

function LoginPage() {
    const user = useUser();
    const referrer = useQueryParam('referrer') ?? '/';
    const {t} = useTranslation();

    return user
        ? <Redirect to={referrer}/>
        : <Layout>
            <Header login={true}/>
            <Layout.Content>
                <Row justify="center" align="middle" className="mt-2">
                    <Col>
                        <Logo/>
                        <Card title={<span className="card-title">{t('login-title')}</span>} className="mt-1" size="large" type="inner">
                            <LoginForm/>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>;
}

export {LoginPage};
