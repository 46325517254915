import React from 'react';
import {Layout, Row, Col, Button, Select,} from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { logout } from '../LoginPage/actions';
import { Logo } from '../../Logo';

function Header({login}) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    return <Layout.Header className="header-layout">
        <Row gutter={[16,0]}>
            <Col span={12}>
                <Logo width={128} />
            </Col>
            <Col span={!login ? 10 : 12} className="tar">
                <Select defaultValue={i18n.language} bordered={false} onSelect={(value)=>i18n.changeLanguage(value)}>
                    <Select.Option value="hu">HU</Select.Option>
                    <Select.Option value="en">EN</Select.Option>
                </Select>
            </Col>
            {!login &&
            <Col span={2}>
                <Button type="link" onClick={() => dispatch(logout())}>
                    {t('logout')}
                </Button>
            </Col>
            }
        </Row>
    </Layout.Header>;
}

export { Header };
