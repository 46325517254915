import { LOGIN } from './actionTypes';

function login(user) {
    return {
        type: LOGIN,
        user
    };
}

function logout() {
    return login(null);
}

export {
    login,
    logout
};
