import React from 'react';
import { render } from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import './i18n';
// import moment from 'moment';
// import 'moment/locale/hu';

import { reducer } from './reducer';
import { App } from './components/App';

import './index.css';

// moment.locale('hu');

const store = createStore(reducer);

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('agrosense-root')
);
